import navigate from "@utils/navigate";
import { prefetch } from "astro:prefetch";
import { useCallback, useEffect } from "react";

interface Props {
  exampleId: string;
  thumbnail: ImageMetadata;
}

export default function ExampleLink({ exampleId, thumbnail }: Props) {
  useEffect(() => {
    prefetch(`/onboarding/${exampleId}`);
    // prefetch(`/floorplan/${exampleId}`);
  }, []);

  const onClick = useCallback(() => {
    navigate(`/onboarding/${exampleId}`);
  }, []);

  return (
    <div className="cursor-pointer" onClick={onClick} role="button">
      <div
        data-example="66532e9e097a0f8be2f74363"
        className="border-2 border-gray-warm-400 rounded-xl bg-center bg-no-repeat bg-cover h-full min-h-24 transition hover:shadow-[inset_0_0_0_1000px_rgba(0,0,0,.2)]"
        style={{ backgroundImage: `url('${thumbnail.src}')` }}></div>
    </div>
  );
}
